// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$customer-portal-primary: mat.define-palette(mat.$indigo-palette);
$customer-portal-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$customer-portal-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$customer-portal-theme: mat.define-light-theme((color: (primary: $customer-portal-primary,
                accent: $customer-portal-accent,
                warn: $customer-portal-warn,
            )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($customer-portal-theme);

$material-design-icons-font-directory-path: '~material-design-icons-iconfont/dist/fonts/';
@import '~material-design-icons-iconfont/src/material-design-icons';

/* You can add global styles to this file, and also import other style files */
// @import '../src/assets/stylesheet/main.scss';
@import '../src/assets/stylesheet/variables';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&amp;display=swap');

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    color: #444;
}
.mat-form-field-appearance-outline .mat-form-field-flex:hover {
  outline: none!important;
  border: transparent!important;
}
.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #ddd !important;
}
.full-width {
    width: 100%;
}
.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
  margin-top: -1px;
  padding-left: 4px!important;
}
.mat-form-field-wrapper {
  padding-bottom: 1.6em;
}
.heading1 {
    font-size: 17px !important;
    font-weight: 500 !important;
    // border-bottom: 1px solid #efefef;
    // background-color: #f2f2f2;
    // padding: 8px 10px;
    // border-radius: 4px;
}

.heading2 {
    font-size: 17px;
}

.heading4 {
    font-size: 16px!important;
}

.heading3 {
    font-size: 15px !important;
    font-weight: 500 !important;
}

.example-form-field {
    font-size: 12px;
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
    background-color: $primary-color;
}

.card1::-webkit-scrollbar {
    width: 5px;
}
.card1::-webkit-scrollbar-track {
    background: #ddd;
}
.card1::-webkit-scrollbar-thumb {
    background: #999;
}
.card1::-webkit-scrollbar-thumb:hover {
    background: #777;
}

.card1 {
    background-color: $white;
    padding: 20px;
    border-radius: 4px;
    height: calc(100vh - 99px);
    overflow-y: auto;
}

.mat-raised-button {
    box-shadow: none !important;
}

.mat-form-field-appearance-outline .mat-form-field-flex:hover {
    outline: none !important;
    border: transparent !important;
}

.breadcrumb {
    border-bottom: 1px solid #eee;
  }


//   BREADCRUMB
  .breadcrumb h4 {
    margin-bottom: 10px;
    text-align: center;
  }

  .breadcrumb .b-left {
    display: inline-flex;
    cursor: pointer;
  }

  .breadcrumb .b-left span {
    padding-top: 3px;
  }

  .breadcrumb .b-left .mat-icon {
    width: auto;
    height: auto;
  }

  .label1{
    font-weight: 500;
    margin-right: 8px;
    margin-bottom: 6px
  }
  .label2{
    color: #888;
    font-weight: 500;
    margin-bottom: 6px;
  }
  .label3{
    /* font-weight: 500; */
    /* color: #666; */
    margin: 4px 0px;
  }
  input:focus,   input:focus{
    outline: none;
  }

  .mat-simple-snackbar-action{
    color: $primary-color;
  }
  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: $primary-color;
  }

  // .no-data{
  //   height: 160px;
  //   vertical-align: middle;
  //   display: table-cell;
  //   width: 1%;
  // }
  // .no-data p{
  //   text-align: center;
  //   font-size: 32px;
  //   font-weight: 700;
  //   color: #e3e3e3;
  //   text-transform: uppercase;
  // }
  
.noDataBox img{
  max-width: 450px;
}
.noDataBox  .no-data{
  font-size: 25px;
  font-weight: 500;
  color: #aaa;
  margin-top: 15px!important;
  text-transform: uppercase;
  background-color: transparent;
}
  .no-data{
    height: 88px;
    background-color: #f7f7f7;
    margin-bottom: 20px;
    border-radius: 4px;
}
.no-data p{
    text-transform: none;
    font-weight: 500;
    font-size: 28px;
    // text-align: left;
    text-align: center;
    vertical-align: middle;
    display: table-cell;
    width: 1%;
    height: 88px;
    color: #ddd;
    padding: 0px 16px;
    line-height: 1;
}
  table.mat-table{
    width: 100%;
    box-shadow: none!Important;
  }
  .pointer{
    cursor: pointer;
  }
@media screen and (max-width: 768px) {
    .card1 {
        height: calc(100vh - 135px);
    }
    .rme-0{
      margin-right: 0px!important;
    }
    .rmb-4{
      margin-bottom: 20px;
    }
}
@media screen and (max-width: 480px) {
    .card1 {
        padding: 15px;
    }
}

// .mat-dialog-container{
//   min-width: 320px;
// }

.link{
  color: $primary-color;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
}
tr.mat-header-row{
  background-color: #eee;
}

.m-project-menu{
  padding: 0px;
}
.m-project-menu .project-menu{
  margin-bottom: 0px!important;
  padding: 14px 20px!important;
  border: 0px!important;
  border-bottom: 1px solid #ddd!important;
  border-radius: 0px!important;
  width: 220px!important;
}
.m-project-menu .pm-icon img{
  height: 22px!important;
}
.m-project-menu .project-menu .pm-text{
  margin-top: 1px!important;
}

